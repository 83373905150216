:root {
  --card-background-color: var(--theme-background-alternate-primary-default);
  --card-background-color-default: var(--theme-background-alternate-primary-default);
  --card-background-color-disabled: var(--theme-background-primary-disabled);
  --card-background-color-pressed: var(--theme-background-alternate-primary-selected);
  --card-border-width: 1px;
  --card-border-radius: 4px;
  --card-border-color: var(--theme-border-tertiary-default);
  --card-spacing-inset: 32px;
  --card-spacing-vertical: 16px;
  --card-drop-shadow-default-offset-x: 0px;
  --card-drop-shadow-default-offset-y: 2px;
  --card-drop-shadow-default-blur: 4px;
  --card-drop-shadow-default-spread: 0px;
  --card-drop-shadow-default-color: rgba(11, 12, 12, 0.16);
  --card-drop-shadow-hover-offset-x: 0px;
  --card-drop-shadow-hover-offset-y: 4px;
  --card-drop-shadow-hover-blur: 8px;
  --card-drop-shadow-hover-spread: 0px;
  --card-drop-shadow-hover-color: rgba(11, 12, 12, 0.16);
  --card-drop-shadow-pressed-offset-x: 0px;
  --card-drop-shadow-pressed-offset-y: 3px;
  --card-drop-shadow-pressed-blur: 6px;
  --card-drop-shadow-pressed-spread: 0px;
  --card-drop-shadow-pressed-color: rgba(11, 12, 12, 0.16);
  --card-offset-vertical-default: 0px;
  --card-offset-vertical-hover: -4px;
  --card-offset-vertical-pressed: -2px;
}